import { AppProps } from "next/app";
import Head from "next/head";
import PlausibleProvider from "next-plausible";
import React, { useEffect } from "react";
import Modal from "react-modal";

import CookieConsent from "../components/CookieConsent";
import "../styles/main.scss";
import "../styles/tarteaucitron.scss";
import "../styles/main.scss";
import defaultMetadata from "../config/metadata";
import { getMediaUrl } from "../utils/api.utils";
import config from "../config";

// See https://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement("#__next");

function MyApp({ Component, pageProps, router }: AppProps) {
  const slug = pageProps?.content?.attributes?.slug ?? "";

  const metaData = pageProps?.content?.attributes?.meta;
  const metaTitle = metaData?.title ? metaData.title : defaultMetadata.title;
  const metaDescription = metaData?.description
    ? metaData?.description
    : defaultMetadata.description;
  const metaImageSrc = metaData?.image?.data?.attributes?.url
    ? getMediaUrl(metaData.image.data?.attributes?.url)
    : defaultMetadata.imageSrc;

  const metaSiteUrl =
    slug === "accueil" ? config.SITE_URL : `${config.SITE_URL}/${slug}`;

  useEffect(() => {
    const handleAnalytics = () => {
      // @ts-ignore
      window?._paq?.push(["trackPageView"]);
    };
    router.events.on("routeChangeComplete", handleAnalytics);

    return () => {
      router.events.off("routeChangeComplete", handleAnalytics);
    };
  }, [router]);

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="icon" href="favicon/favicon.svg" />
        <meta property="og:url" content={metaSiteUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImageSrc} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={metaSiteUrl} key="canonical" />
      </Head>
      <CookieConsent />
      <PlausibleProvider domain="rapport-activites.esante-occitanie.fr">
        <Component {...pageProps} key={router.route} />
      </PlausibleProvider>
    </>
  );
}

export default MyApp;
