import Script from "next/script";
import React, { FC, useEffect } from "react";

import styles from "./CookieConsent.module.scss";
import { useMedia } from "react-use";

export interface ICookieConsentProps {}

const CookieConsent: FC<ICookieConsentProps> = (props) => {
  const [libLoaded, setLibLoaded] = React.useState(false);
  const isPrint = useMedia("print", false);

  useEffect(() => {
    // @ts-ignore
    if (window) window.tarteaucitronForceLanguage = "fr";
  }, []);

  return !isPrint ? (
    <>
      <Script strategy="beforeInteractive" src="/tarteaucitron.js" />
      <Script
        strategy="afterInteractive"
        src={
          process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
            ? "/tarteaucitron.init.js"
            : "/tarteaucitron.init.preview.js"
        }
      />
    </>
  ) : (
    <></>
  );
};

CookieConsent.defaultProps = {};

export default CookieConsent;
