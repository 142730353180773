import { ApiEngineSources } from "../api-engine";

const config = {
  API_ENGINE_SRC: (process.env.NEXT_PUBLIC_API_ENGINE_SRC ??
    "local") as ApiEngineSources,
  SITE_URL:
    process.env.NEXT_PUBLIC_SITE_URL ?? process.env.VERCEL_URL
      ? `https://${process.env.VERCEL_URL}`
      : "http://localhost:3000",
};

export default config;
